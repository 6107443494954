/*------------------------------------------------------------------
  [Progress bars]
*/
.progress {
	height: $progress-bar-height;
	box-shadow: none;
}

.progress-bar{
	box-shadow: none;
	line-height: $progress-bar-height;
}