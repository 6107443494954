/*------------------------------------------------------------------
	[Main wrapper]
*/
.be-wrapper{
	min-height: 100%;
	padding-top: $top-header-height + 1px;

  &.be-centered {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

	@media #{$phone} {
		overflow-x: hidden;
	}

	@media #{$print} {
		padding-top: 0;
	}
}
