#field-map-modal-tabs .tab-content {
  padding: 20px 0 0 0;
  margin-bottom: 0;
}

.ln-field-map.row.form-group {
  padding-top: 3px;
  padding-bottom: 3px;
}

.ln-field-map label.control-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: inherit!important;
}

.ln-field-map label.align-left {
  text-align: left!important;
}
