/*------------------------------------------------------------------
  [Custom pages]
*/
/*------------------------------------------------------------------
  [Login]
*/

//Login container for remember me and forgot password
.login-tools{
	padding-top: 18px;
}

//Login forgot password link
.login-forgot-password{
	line-height: $input-check-component-size;
	text-align: right;
}

//Login submit
.login-submit{
	padding-top: 19px;
	margin-bottom: $form-group-margin-bottom - 2px;

	.btn{
		width: 100%;
	}

	//Reduce bs col padding
	> div{
		
		&:first-child{
			padding-right: 10px;
		}

		&:last-child{
			padding-left: 10px;
		}
	}
}