.qrtd-image-container {
  background: white;
  padding: 7px 15px 0 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

.qrtd-image-container h4 + h5 {
  margin-top: -5px;
}

.qrtd-image-preview {
  margin-bottom: 10px;
}

.qrtd-image-preview img {
  max-width: 100%;
}

.qrtd-image-container .form-group {
  margin-bottom: 10px;
}
