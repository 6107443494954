/*------------------------------------------------------------------
  [Forms]
*/
/*------------------------------------------------------------------
  [Switch button]
*/
@mixin switch-button-size($size-base){
  height: $size-base;
  width: $size-base + 33px;
  line-height: $size-base - 4px;

  label{
    height: $size-base - 8px;
    width: $size-base - 8px;

    &:before{
      line-height: $size-base + 1px;
    }
  }
}

@mixin switch-button-color($active-color, $off-color: darken($light-color, 30%)){
  background-color: $off-color;

  label{
    border-color: darken($off-color, 3%);
  }

  input[type="checkbox"]:checked + span{ 
    background-color: $active-color;

    label{
      border-color: darken($active-color, 5%);
    }
  }
}

.switch-button{
  display: inline-block;
  border-radius: 50px;
  background-color: darken($light-color, 30%);
  width: 60px;
  height: 27px;
  padding: 4px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  
  input[type="checkbox"]{
    display: none;
  }
  
  label{
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #FFF inset;
    background-color: $light-color;
    margin: 0;
    height: 19px;
    width: 19px;
    z-index: 1;
    display: inline-block;
    cursor: pointer;
    background-clip: padding-box;
    
    &:before{
      position: absolute;
      font-size: 11px;
      font-weight: 600;
      z-index: 0;
      content: "OFF";
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 27px;
      top: 0;
      text-align: right;
      padding-right: 10px;
      color: $light-color;
    } 								    
  }
  
  input[type="checkbox"]:checked + span{ 
    background-color: $primary-color;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4px;
    
    label{
      float: right; 
      border-color: darken($primary-color, 5%);
    
      &:before{
        position: absolute;
        font-size: 11px;
        font-weight: 600;
        z-index: 0;
        content: "ON";
        color: $light-color;
        left: 0;
        text-align: left;
        padding-left: 10px;
      }
    }
  }
 
  //Yes-No labels
  &.switch-button-yesno{
    label{
 
      &:before{
        content: "NO";
      }
    }
 
    input[type="checkbox"]:checked + span{
 
      label{
        &:before{
          content: "YES";
        }
      }
    }
  }

  //Sizes
  &.switch-button-xs{
    @include switch-button-size(20px);
    width: 50px;
  }

  &.switch-button-sm{
    @include switch-button-size(24px);
  }

  &.switch-button-lg{
    @include switch-button-size(30px);
    width: 65px;
  }

  //Color styles
  &.switch-button-success{
    @include switch-button-color($success-color);
  }
  
  &.switch-button-warning{
    @include switch-button-color($warning-color);
  }

  &.switch-button-danger{
    @include switch-button-color($danger-color);
  }
}
