//Icons nav
.be-icons-nav{

	.be-top-header & {
		margin-right: 20px;
	}

	@media #{$non-responsive} {
		.be-right-navbar-flex & {
			flex: 0 0 auto;
		}
	}

	@media #{$tablet}{
		
		.be-top-header & {
			margin-right: 10px;
		}
	}

	> li.dropdown{
		position: static;

		> a{
			padding: 0 10px;

			.icon{
				font-size: 24px;
				vertical-align: middle;
				color: $icon-default-color;

				&.mdi-comment-more{
					font-size: 20px;
					position: relative;
					top: 1px;
				}
			}

			.indicator{
				background-color: $primary-color;
				border-radius: 50%;
				display: block;
				height: 6px;
				width: 6px;
				position: absolute;
				top: 18px;
				right: 6px;
			}
		}
		
		&.open{
			
			> a:after, > a:before{
				border-bottom: 8px solid $light-color;
		    border-left: 8px solid transparent;
		    border-right: 8px solid transparent;
		    bottom: -13px;
		    content: "";
		    display: inline-block;
		    left: 50%;
		    margin-left: -8px;
		    position: absolute;
		    z-index: $zindex-dropdown + 1;
			}

			> a:before{
				border-bottom-width: 9px;
				border-left-width: 9px;
				border-right-width: 9px;
				border-bottom-color: $dropdown-border;
				margin-left: -9px;
				bottom: -12px;
			}
		}

		.dropdown-menu{
			width: $icons-nav-dropdown-width;
			border-radius: 3px;
			padding: 0;
			box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
			border: 1px solid hsl(0, 0%, 88%);
			box-sizing: content-box;

			.title{
				font-size: 13px;
				color: darken($light-color, 45.7%);
				text-align: center;
				padding: 10px 0 8px;
				border-bottom: 1px solid darken($light-color, 7.7%);

				.badge{
					font-size: $font-size-small - 2px;
					line-height: 10px;
					vertical-align: middle;
					margin-left: 4px;
					background-color: darken($light-color, 10.5%);
					color: darken($light-color, 45.7%);
					min-width: 17px;
					height: 17px;
					text-align: center;
					padding: 3px 6px;

					&-new{
						background-color: $primary-color;
						border-color: $primary-color;
						color: $light-color;
					}
				}
			}

			.footer{

				a{
					background-color: darken($light-color, 2%);
					font-size: 13px;
					color: darken($light-color, 45.7%);
					text-align: center;
					padding: 10px 0;
					border-top: 1px solid darken($light-color, 7.7%);
					display: block;
					outline: none;

					&:hover{
						background-color: darken($light-color, 4%);
					}
				}	
			}

			.be-top-header & {
				&:after, &:before{
					display: none;
				}
			}
		}

		&.open{

			> a{
				background-color: transparent;

				.icon{
					color: $primary-color;

					@media #{$phone} {
						color: $light-color;
					}
				}
			}
		}
	}

	@media #{$phone} {
		float: right;
		flex: 0 0 auto;

		.be-top-header & {
			margin: 0 5px 0 0;
		}

		> li.dropdown{
			display: inline-block;

			> a{
				padding: 0 7px;

				.icon{
					color: $light-color;
				}

				.indicator{
					right: 2px;
					background-color: $light-color;
				}
			}
		}
	}

	@media #{$small-phone} {

		> li.dropdown{

			.dropdown-menu{
				left: 0;
				top: $top-header-height;
				width: 100%;
				box-shadow: none;
				border-width: 0;

				.be-top-header & {
					border-radius: 0;
					margin-top: 1px;
				}

				.footer{

					a{
						border-bottom: 1px solid darken($light-color, 7.7%);
					}
				}
			}

			&.open{
				
				> a{
					
					&:before{
						display: none;
					}

					&:after{
						bottom: -1px;
					}
				}
			}
		}
	}
}