/*------------------------------------------------------------------
[Fonts - Heebo]
*/

@mixin heebo-font($type, $weight, $style) {
    @font-face {
        font-family: 'Heebo';
        src: local('Heebo #{$type}'),
             local('Heebo-#{$type}'),
             url('#{$heebo-font-path}/Heebo-#{$type}.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
    }

    @font-face {
        font-family: 'Heebo-#{$type}';
        src: local('Heebo #{$type}'),
             local('Heebo-#{$type}'),
             url('#{$heebo-font-path}/Heebo-#{$type}.ttf') format('truetype');
    }
}

@mixin heebo-font-pair($type, $weight) {
    @include heebo-font('#{$type}', $weight, normal);
}

@include heebo-font-pair('Thin', 100);
@include heebo-font-pair('Light', 300);
@include heebo-font-pair('Regular', 400);
@include heebo-font-pair('Medium', 500);
@include heebo-font-pair('Bold', 700);
@include heebo-font-pair('ExtraBold', 800);
@include heebo-font-pair('Black', 900);
