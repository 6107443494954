/*------------------------------------------------------------------
  [Tabs]
*/
.nav-tabs{
	background-color: $light-color;
	border-bottom: 2px solid darken($light-color, 8%);

	> li{
		margin-right: 2px;
		margin-bottom: -2px;

		a{
			padding: 12px 20px;
			margin-right: 0;  
			min-width: 60px;
    	text-align: center;
    	border-radius: 0;
    	color: $text-color;
    	border-width: 0;

    	.icon{
    		font-size: 20px;
    		vertical-align: middle;
    		margin-right: 6px;
    		line-height: 17px;
    	}

    	&:hover{
    		background: transparent;
				color: $primary-color;
    	}

    	&:active{
    		background-color: transparent;
    	}
		}

		&.active{
			position: relative;
			background: transparent;
			color: $primary-color;

			a, a:hover, a:focus{
				background-color: $light-color;
				border: none;
				border-bottom: 2px solid $primary-color;
			}
		}
	}
}

.tab-content{
	background: $light-color;
	padding: 20px;
	margin-bottom: 40px;
	border-radius: 0 0 3px 3px;

	.tab-pane{
		
		//First heading margin-top fix
		h1, h2, h3, h4, h5, h6{

			&:first-child{
				margin-top: 5px;
			}
		}
	}
}

// Navs Color States

.nav-tabs-success{
	@include tabs-color($success-color);
}
.nav-tabs-warning{
	@include tabs-color($warning-color);
}
.nav-tabs-danger{
	@include tabs-color($danger-color);
}