/*------------------------------------------------------------------
  [User profile widget]
*/
.user-display{
	margin-bottom: 30px;
	border-radius: $border-radius-base;
	background-color: $light-color;

	&-bg{
		max-height: 300px;
		overflow: hidden;

		img{
			width: 100%;
			border-radius: $border-radius-base $border-radius-base 0 0;

			@media #{$phone} {
				min-height: 130px;
			}
		}
	}

	&-bottom{
		position: relative;
		padding: 11px 20px;
	}

	&-avatar{
		position: absolute;
		left: 20px;
		top: -50px;
		border-radius: 50%;
		background-color: $light-color;

		img{
			width: $user-profile-widget-avatar-size;
			height: $user-profile-widget-avatar-size;
			border-radius: 50%;
			border: 3px solid $light-color;
		}
	}

	&-info{
		line-height: 20px;
		padding-left: $user-profile-widget-avatar-size + 20px;

		.name{
			display: block;
			@include font-size-large(3px);
			font-weight: $font-weight-medium;
			line-height: 22px;
		}

		.nick{
			line-height: 21px;
			color: lighten($text-color, 12%);
		}
	}

	&-details{
		margin-top: 15px;

		.title{
			text-transform: uppercase;
			font-size: $font-size-small;
			color: lighten($text-color, 12%);
		}

		.counter{
			@include font-size-large(3px);
		}
	}
}