.alert.welcome-message .message h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.alert.welcome-message .message > p {
  margin-bottom: 15px;
  font-size: 15px;
}

.alert.welcome-message .message > p:last-child {
  margin-bottom: 5px;
}
