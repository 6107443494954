.switch-button-panel label {
  margin-right: 10px;
}

.invoice .invoice-details td {
  vertical-align: top;
}

.invoice td.description .description-tiny {
  padding: 0;
  margin: 5px 0 0 20px;
  list-style-type: circle;
  font-size: 12px;
}

@media print {
  .switch-button-panel {
    display: none;
  }
  .invoice td.description .description-tiny a {
    color: inherit;
  }
  .invoice td.description .description-tiny a[href]:after {
    display: none;
  }
}
