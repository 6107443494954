/*------------------------------------------------------------------
  [Label]
*/
.label{
	border-radius: 2px;
	padding: 3px 7px;
	font-size: $font-size-small - 1px;
	font-weight: $font-weight-bold;
}

.label-default{
	border: 1px solid darken($light-color, 15%);
	color: $text-color;
}