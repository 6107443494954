.fstd-fieldmap.panel  {
  margin-bottom: 20px;
}

.fstd-fieldmap.is-new.panel-border .panel-heading,
.fstd-fieldmap.is-not-editing.panel-border .panel-heading {
  border-bottom: none;
}

.fstd-fieldmap .alert {
  margin-bottom: 0;
}

.fstd-fieldmap .fstd-fieldmap-error.alert {
  font-size: 13px;
}

.fstd-fieldmap .header-form {
  display: flex;
  flex-wrap: wrap;
}

.fstd-fieldmap .header-form input {
  height: 40px;
}

.fstd-fieldmap .header-form .fileName,
.fstd-fieldmap .header-form .assetTemplate {
  flex: 1;
}

.fstd-fieldmap .header-form button {
  flex: 0;
  flex-basis: 40px;
  margin-left: 5px;
  margin-right: 5px;
  border-color: #d5d8de;
}

.fstd-fieldmap .btn-direction {
  height: 40px;
}

.fstd-fieldmap .form-group-sm {
  font-size: 12px;
}

.fstd-fieldmap .form-group-sm select + .select2 .select2-selection__rendered,
.fstd-fieldmap .form-group-sm select + .select2 .select2-selection__arrow b:after,
.fstd-fieldmap .form-group-sm select + .select2 .select2-selection
{
  height: 40px!important;
  line-height: 38px!important;
  font-size: 12px;
}

.fstd-fieldmap .form-group-sm select + .select2  .select2-selection .select2-selection__arrow b:after {
  font-size: 25px;
}

.fstd-fieldmap .parsley-errors-list.filled {
  font-size: 12px;
  padding: 10px 15px;
}
