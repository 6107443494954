.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.spinner {
  margin-left: auto;
  margin-right: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.6em solid #036abc;
  border-right: 0.6em solid #036abc;
  border-bottom: 0.6em solid rgba(3, 106, 188, 0.2);
  border-left: 0.6em solid rgba(3, 106, 188, 0.2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
