/*------------------------------------------------------------------
  [Sub Navigation]
*/

.be-sub-navigation {
	background-color: $sub-navigation-bg;
	border-color: darken($sub-navigation-bg, 7%);
	border-width: 0;
	border-bottom-width: 1px;

	@media #{$phone} {
		background-color: $top-header-bg;
		border-bottom-width: 0;
	}

	.navbar-collapse {
		border-color: darken($sub-navigation-bg, 7%);
		padding: 0;

		@media #{$phone} {
			border-top: 1px solid $left-sidebar-bg;
		}
	}

	.navbar-nav {
		display: flex;
		float: none;
		justify-content: center;

		@media #{$phone} {
			flex-direction: column;
			margin: 0;
			padding: 15px 0;
			background-color: lighten($left-sidebar-bg, 2%);
		}

		> li {

			&.open {

				> a, > a:focus {
					color: $sub-navigation-focus-color;
				}

				> a:hover {
					color: $primary-color;
				}
			}

			&.dropdown {

				.dropdown-menu {

					margin-top: 12px;

					&:after, &:before{
						border-bottom: 8px solid white;
				    border-left: 8px solid transparent;
				    border-right: 8px solid transparent;
				    top: -8px;
				    content: "";
				    display: inline-block;
				    left: 42px;
				    margin-left: -6px;
				    position: absolute;
					}

					&:before{
						border-bottom-width: 9px;
						border-left-width: 9px;
						border-right-width: 9px;
						border-bottom-color: $dropdown-border;
						margin-left: -7px;
						top: -9px;
					}
				}
			}					

			> a {

				@media #{$phone} {
					font-size: 14px;
					padding: 10px 20px;
					color: $left-sidebar-color;
				}

				&:focus {
					color: $navbar-default-brand-color;
				}

				&.dropdown-toggle {

					.mdi-caret-down {
						font-size: 18px;
						vertical-align: middle;
						margin-left: 3px;
						margin-top: -2px;

						@media #{$phone} {
							color: darken($left-sidebar-bg, 19%);
							float: right;
    					line-height: 1.3;
						}
					}
				}
			}

			@media #{$phone} {
				
				&.dropdown {
					
					.dropdown-menu{
						background-color: $left-sidebar-sub-level-bg;
						padding: 10px 0;
						border-top: 1px solid darken($left-sidebar-bg, 6%);
						border-bottom: 1px solid darken($left-sidebar-bg, 6%);
						border-radius: 0;

						&:before, &:after{
							display: none;
						}

						> li{

							> a{
								color: $left-sidebar-sub-level-color;
								padding: $left-sidebar-sub-level-padding;
								padding-left: 23px;
							}
						}
					}
				}
			}
		}
	}
}

.be-sub-navigation-collapse {

	padding: $sub-navigation-collapse-padding;
	color: $text-color;
	border-bottom: 1px solid $left-sidebar-bg;

	&.collapsed{
		border-bottom: 1px solid darken($left-sidebar-bg, 7%);
		transition: border-bottom-color 0.4s ease;

		&:before{
			content: '\f2f2';
		}
	}

	&:before {
		content: '\f2f8';
    float: right;
    font-family: 'Material Icons';
    font-size: 27px;
    line-height: 22px;
    color: darken($left-sidebar-bg, 19%);
	}

	&:focus {
		color: $text-color;
	}

	&:hover {
		color: darken($light-color, 80%);
	}

	@media #{$phone} {
		display: block;
		font-size: 14px;
		font-weight: $font-weight-light;
	}

	@media #{$desktop} {
		display: none;
	}

	@media #{$laptop} {
		display: none;
	}

	@media #{$tablet} {
		display: none;
	}
}