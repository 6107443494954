.qrtd-print-page .print-page {
  margin: 30px auto;
}

.qrtd-print-page .qrtd-print-page-container {
  margin: 0 auto;
  width: 50%;
}

.qrtd-print-page .qrtd-print-page-toolbox {
  background: #fff;
  border: 1px solid #d4d4d4;
  border-top: none;
}

.qrtd-print-page .qrtd-print-page-code-list {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
}

.qrtd-print-page .qrtd-print-page-code-list > li {
  margin: 5mm;
  float: left;
  page-break-inside: avoid;
}

.qrtd-print-page .qr-dummy {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1pt dashed black;
}

.qrtd-print-page .qrtd-print-page-qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1pt solid black;
  padding: 2mm 2mm 1.25mm 2mm;
}

.qrtd-print-page .qrtd-print-page-qr-code-container input, .qrtd-print-page .qrtd-print-page-qr-code-container textarea {
  width: 100%;
  text-align: center;
  border: none;
  padding: 0;
  background: transparent;
}

.qrtd-print-page .qrtd-print-page-qr-code-container input[name="topText"] {
  margin-bottom: 2mm;
}

.qrtd-print-page .qrtd-print-page-qr-code-container textarea[name="bottomText"] {
  margin-top: 1.5mm;
  overflow: hidden;
  height: auto;
}

.qrtd-show-modal-body .link-container a {
  display: inline-block;
  width: 75%;
  overflow-wrap: break-word;
}

@media print {
  .qrtd-print-page .print-page {
    margin: 0;
  }

  .qrtd-print-page .qrtd-print-page-container {
    display: none;
  }
}
