.alert-enter {
  opacity: 0.01;
}

.alert-enter.alert-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.alert-exit {
  opacity: 1;
}

.alert-exit.alert-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in-out;
}
