.support-question-list-panel.panel {
  margin-bottom: 5px;
}

.support-question-list-panel .panel-body {
  padding: 0!important;
}

.support-question-list-panel .panel-body .panel-body-inner {
  padding: 15px 15px 0 15px;
}

.support-question-list-panel .panel-body .panel-body-actions {
  padding: 15px;
  border-top: 1px solid #ddd;
  background: #f5f5f5;
}


.support-question-list-header {
  border-bottom: 1px solid #ddd;
}

.support-question-list-header:hover {
  cursor: pointer;
}

.support-question-list-icon {
  font-size: inherit!important;
}
