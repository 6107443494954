/*------------------------------------------------------------------
  [List group]
*/
.list-group-item {
	padding: 13px 15px;

	.icon{
		margin-right: 15px;
		font-size: 16px;
    &.nudge-down {
      position: relative;
      top: 1px;
    }
	}

	&.active{
		.icon{
			color: $light-color;
		}
	}
}
