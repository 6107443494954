.invoice .invoice-status {
  display: inline-block;
}

.invoice .invoice-message .title {
  margin-bottom: 5px;
}

.invoice .invoice-message ul {
  padding-left: 20px;
  line-height: 1.5em;
}

.invoice ol.vat-rates, .invoice .vat-rates ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.invoice ol.vat-rates {
  overflow: hidden;
}

.invoice ol.vat-rates li.left > span {
  display: inline-block;
  margin-right: 10px;
}

.invoice ol.vat-rates li.right > span {
  display: inline-block;
  margin-left: 10px;
}

.invoice .vat-rates li.left {
  float: left;
}

.invoice .vat-rates li.right {
  float: right;
}

.invoice .invoice-company-info .summary p {
  line-height: 20px;
}
