/*------------------------------------------------------------------
  [Dropdown menu]
*/
// div container for the dropdown showcase
.dropdown-showcase{
	margin: 0;

	.showcase{
		display: inline-block;
		margin-right: 18px;
	}
}

//Dropdown Menu Style
.dropdown-menu{
	box-shadow: $dropdown-box-shadow;
	border-radius: 3px;
	padding: 7px 0px;
	min-width: 185px;

	> li{

		//First level
		> a{
			font-weight: $font-weight-regular;
			color: $dropdown-text-color;
			padding: 8px 20px;

			.icon{
				font-size: 16px;
				line-height: normal;
				vertical-align: middle;
				margin-right: 8px;
				color: darken($light-color, 59%)
			}

			&:focus, &:active{
				background-color: transparent;
				color: $dropdown-text-color;
			}

			&:hover{
				background-color: darken($light-color, 4%);
			}
		}

		.btn-group{
			.btn{
				font-size: 16px;
				color: darken($light-color, 59%);
				padding: 0 20px;
			}
		}
	}
	
	//Divider Line
	.divider{
		margin: 6px 0;
		background-color: $dropdown-border;
	}
}

//Dropdown Header
.dropdown-header{
	padding: 5px 20px 1px;
	font-weight: 600;
	color: lighten($dropdown-text-color, 45%);
	cursor: default;
}

//Dropdown Tools
.dropdown-tools{
	text-align: center;
}

//Dropdown Color Set
.dropdown-menu-primary{
	@include dropdown-color($primary-color-text);
}

.dropdown-menu-success{
	@include dropdown-color($success-color-text);
}

.dropdown-menu-warning{
	@include dropdown-color($warning-color-text);
}

.dropdown-menu-danger{
	@include dropdown-color($danger-color-text);
}
