/*------------------------------------------------------------------
  [Forgot password]
*/
.splash-container.forgot-password{

	.panel{
		
		.panel-heading{
			margin-bottom: 5px;
		}
	}
}