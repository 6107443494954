.account-add-card-form .StripeElement {
  background-color: white;
  padding: 4px 12px;
  border-radius: 2px;
  border: 1px solid #d5d8de;
  border-top-color: #bdc0c7;
  font-size: 13px;
}

.account-add-card-form .form-group.required .control-label:after {
  content: " *";
  color: #fa755a;
}

.account-add-card-form .StripeElement--focus {
  border-color: #036abc;
}

.account-add-card-form .StripeElement--complete {
  border-color: #37b358;
}

.account-add-card-form .StripeElement--invalid {
  border-color: #fa755a;
}

.account-add-card-form .StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
