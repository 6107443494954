.linknow-user-nav li.dropdown a.active {
  color: #036abc;
}

.linknow-user-nav li hr.final-divider {
  margin-top: 8px;
  margin-bottom: 8px;
}

.linknow-user-nav.be-user-nav > li.dropdown > a .user-name {
  display: inline-block;
}

/* show the caret */
.linknow-user-nav.be-user-nav > li.dropdown > a:after, .be-user-nav > li.dropdown > button:after {
  /* display: inline-block; */
  /* color: #fff; */
}
