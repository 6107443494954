.marketplace-item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -10px;
}

.marketplace-item-list > .marketplace-item {
  margin: 10px;
  flex-basis: calc((100% / 1) - 20px);
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .marketplace-item-list > .marketplace-item {
    margin: 10px;
    flex-basis: calc((100% / 2) - 20px);
  }
}

@media (min-width: 992px) {
  .marketplace-item-list > .marketplace-item {
    margin: 10px;
    flex-basis: calc((100% / 3) - 20px);
  }
}
