.instance-widget-plan {
  height: 100%;
}

.instance-widget-plan .panel-heading {
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.instance-widget-plan .subscription-status-label {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding-bottom: 0;
  padding-top: 0;
}

.instance-widget-plan abbr.plan-name {
  border-bottom: none;
}

