.print-page {
  background: #fff;
  box-shadow: 2px 2px 3px #00000017;
}

.print-page-a4 {
  width: 210mm;
  min-height: 297mm;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    background-color: transparent!important;
  }
  .print-page {
    background: transparent;
    box-shadow: none;
  }
}
