.file-upload  {
  display: flex;
  flex-wrap: wrap;
}

.file-upload .btn-group {
  margin-right: 5px;
  vertical-align: top;
}

.file-upload .progress {
  width: 100%;
  height: 100%;
}

.file-upload .progress.no-transition .progress-bar {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.file-upload .status {
  display: block;
  border: 1px solid #dedede;
  padding: 1px;
  height: 30px;
  line-height: 27px;
  flex: 1;
  border-radius: 2px;
}

.file-upload .status .error-message {
  margin-left: 5px;
  text-overflow: ellipsis;
}

.file-upload label {
  vertical-align: top;
  margin-right: 5px;
}
