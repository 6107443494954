//Full colored panel
@mixin panel-full-color($color){
  background-color: $color;
  color: $light-color;

  .panel-heading-contrast{
      background-color: lighten($color, 3%);
      padding: 20px 20px 15px;
      margin: 0;
  }

  .tools{
    .icon{
      color:$light-color;
      &:hover{
        color: darken($color, 6%);
      }
    }
  }
}