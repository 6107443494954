.form-control {
  text-overflow: unset!important;
}
.ln-app {
  /* height: 100%; */
}

.ln-app .be-datatable-body {
  /* needed for sideways scrolling, but unfortunately breaks vertically small tables with dropdowns */
  overflow-x: auto;
}

.ln-app .be-datatable-body.no-overflow {
  overflow-x: visible;
}

.ln-app .alert .close {
  position: static;
  margin-left: 3px;
}

.ln-app .alert-icon.alert-warning > .icon {
  border: 1px solid #ce9a00;
}

.be-top-header .navbar-header .navbar-brand {
  background: none;
  display: flex;
  align-items: center;
}

.invoice-logo {
  background: none;
}

.vertical-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dead-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.height-max {
  height: 100%!important;
}

.row.flex-match-height {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
}
.row.flex-match-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.btn-static {
  background-color: white;
  border: 1px solid lightgrey;
  cursor: default;
}
.btn-static:active {
  -moz-box-shadow:    inset 0 0 0px white;
  -webkit-box-shadow: inset 0 0 0px white;
  box-shadow:         inset 0 0 0px white;
}
.col-centered {
  float: none!important;
  margin: 0 auto;
}

.ln-app .has-error .input-group-addon {
  color: #fff;
}

.ln-app .form-group.has-error .select2 .select2-selection .select2-selection__arrow b:after {
  content: "";
}

.be-icons-nav > li.dropdown .dropdown-menu .footer .inner {
  background-color: #fafafa;
  font-size: 13px;
  color: #8a8a8a;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ebebeb;
  display: block;
  outline: none;
}

.ln-app .dropdown-menu > li > a .icon {
  width: 15px;
  display: inline-block;
  text-align: center;
}

.ln-app .panel-heading .tools .icon {
  color: #646464;
}

.ln-app .dropdown-header {
  padding-bottom: 5px;
}

.ln-app .dropdown.tools a.dropdown-toggle {
  box-shadow: none;
}

.modal-dialog.modal-lg .modal-content {
  max-width: 900px;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 480px) {
  .be-icons-nav > li.dropdown .dropdown-menu .footer .inner {
    border-bottom: 1px solid #ebebeb;
  }
}
