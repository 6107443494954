.ln-subscription-error.alert {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ln-subscription-heading-status.label {
  position: relative;
  top: -2px;
  padding: 2px 5px;
}


.ln-subscription-panel .btn.btn-warning .icon {
  color: #fff;
}
