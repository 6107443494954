//
// Popovers
// --------------------------------------------------

.popover {

  &.bottom > .arrow {
    border-bottom-color: $popover-title-bg;
    top: -10px;

    &:after{
      background-color: transparent;
      top: -2px;
      margin-left: -10px;
      border-bottom-color:$popover-border-color;
      border-top-width: 1px;
      z-index: -1;
    }
  }
}