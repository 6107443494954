.pricing-tables, .pricing-tables-form {
  text-align: center;
}

.pricing-tables-form {
  margin: 30px 0 20px;
}

.pricing-tables-form .panel {
  margin: 0 5px 0 5px;
}

.pricing-tables-form label {
  line-height: 48px;
  margin-bottom: 0;
  font-size: 18px;
}

.pricing-tables-form > .col-md-9, .pricing-tables > .col-md-3 {
  float: none;
  display: inline-block;
  text-align: left;
}
