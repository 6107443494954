.pricing-table {
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
}

.pricing-table p.pricing-table-features {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.75em;
}

.pricing-table .pricing-table-title-small {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 100;
}
